import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';


const ContactUs = () => {

  const navigate = useNavigate();
  const [footerAddress, setFooterAddress] = useState([]);
  const [footerSocial, setFooterSocial] = useState([]);


  useEffect(() => {
    fetch(`https://packagingseo17clone-a6ecda5b6b5b.herokuapp.com/footer-social`)
      .then((res) => res.json())
      .then((info) => setFooterSocial(info));
  }, []);

  useEffect(() => {
    fetch(`https://packagingseo17clone-a6ecda5b6b5b.herokuapp.com/footer-address`)
      .then((res) => res.json())
      .then((info) => setFooterAddress(info));
  }, [footerAddress]);





  const handleMessage = (event) => {
    event.preventDefault();
    const name = event.target.name.value;
    const email = event.target.email.value;
    const number = event.target.number.value;
    const subject = event.target.subject.value;
    const message = event.target.message.value;
    const messageStatus = event.target.messageStatus.value;


    const addItem = {
      name,
      email,
      number,
      subject,
      message,
      messageStatus,

    };

    const url = `https://packagingseo17clone-a6ecda5b6b5b.herokuapp.com/add-message`;
    fetch(url, {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(addItem),
    })
      .then((res) => res.json())
      .then((result) => {

        alert('Message is Send');
      });
  };

  const [action, setAction] = useState([]);

  useEffect(() => {
    fetch(`https://packagingseo17clone-a6ecda5b6b5b.herokuapp.com/actions`)
      .then((res) => res.json())
      .then((info) => setAction(info));
  }, []);



  return (





    <>

      <section className="contact-us-1 section-space overflow-hidden" id='contact-sec'>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="contact-us-1__form-wrapper d-flex flex-column flex-lg-row justify-content-between">
                <div className="contact-us-1__form-left">
                  <h3 className="contact-us-1__form-title mb-35 mb-xs-30">Get in Touch</h3>
                  {
                    footerAddress.map(a=> <div className="contact-us-1__form-item-list d-flex flex-column">
                      <div className="contact-us-1__form-item d-flex align-items-center">
                        <div className="icon">
                          <img src="assets/imgs/contact/phone.png" alt="image not found" />
                        </div>
                        <div className="text">
                          <span>Call us at</span>
                          <a href="/">{a.phoneNumber}</a>
                        </div>
                      </div>
                      <div className="contact-us-1__form-item d-flex align-items-center">
                        <div className="icon">
                          <img src="assets/imgs/contact/email.png" alt="image not found" />
                        </div>
                        <div className="text">
                          <span>Email us on</span>
                          <a href="/">{a.emailAddress}</a>
                        </div>
                      </div>
                      <div className="contact-us-1__form-item d-flex align-items-center">
                        <div className="icon">
                          <img src="assets/imgs/contact/pin.png" alt="image not found" />
                        </div>
                        <div className="text">
                          <span>Address us</span>
                          <a href="https://maps.app.goo.gl/z8EPw8zWqqzP3R1x8">{a.location}</a>
                        </div>
                      </div>
                    </div>)
                  }

                  
                 
                </div>
                <div className="contact-us-1__form-right">
                  <h3 className="contact-us-1__form-title mb-35 mb-xs-30">Send Us a Message</h3>
                  <div className="contact-us-1__form">
                    <form onSubmit={handleMessage}>
                      <div className="row">
                        <div className="col-sm-6">
                          <div className="contact-us-1__input">
                            <input name="name" type="text" placeholder="Your Name" />
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="contact-us-1__input">
                            <input name="email"  type="email" placeholder="Your Email" />
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="contact-us-1__input">
                            <input name="number"  type="text" placeholder="Your Phone" />
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="contact-us-1__input">
                            <input name="subject"  type="text" placeholder="Your Subject" />
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="contact-us-1__input">
                          <input  name="messageStatus"  value="UnRead" hidden placeholder="Message Status" />
                          </div>
                        </div>
                        
                        <div className="col-12">
                          <div className="contact-us__textarea">
                            <textarea name="message"  cols={30} rows={10} placeholder="Write Message..." defaultValue={""} />
                          </div>
                        </div>
                        <div className="col-12">
                          <button type='submit' className="rr-btn rr-btn__theme-4 mt-40 mt-xs-30">
                            <span className="btn-wrap">
                              <span className="text-one">Submit</span>
                              <span className="text-two">Submit</span>
                            </span>
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>


    </>





  );
};

export default ContactUs;