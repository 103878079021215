// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDJr0C7b7IvcAEM6-E8REl30D3v9O6fxCs",
  authDomain: "packagingseo17clone.firebaseapp.com",
  projectId: "packagingseo17clone",
  storageBucket: "packagingseo17clone.firebasestorage.app",
  messagingSenderId: "551977266242",
  appId: "1:551977266242:web:0c2d8e5a2d4aa71bc491db"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const auth = getAuth(app);

export default auth;