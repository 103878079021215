import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { useAuthState } from "react-firebase-hooks/auth";
import auth from "../../firebase.init";
import { signOut } from "firebase/auth";



const NavBar = () => {

  const [user] = useAuthState(auth);
  const handleSignOut = () => {
    signOut(auth);
  }
  const [users, setUser] = useState([]);
  const [logo, setLogo] = useState([]);

  useEffect(() => {
    fetch(`https://packagingseo17clone-a6ecda5b6b5b.herokuapp.com/logo`)
      .then((res) => res.json())
      .then((info) => setLogo(info));
  }, []);


  useEffect(() => {
    fetch(`https://packagingseo17clone-a6ecda5b6b5b.herokuapp.com/users`)
      .then((res) => res.json())
      .then((info) => setUser(info));
  }, []);




  return (



    // <>  

    // {
    //   logo.map(l=><header className="w-100 flaot-left header-con main-box nav-bg position-relative">
    //     <div className="wrapper">
    //       <nav className="navbar navbar-expand-lg navbar-light">
    //         <a className="navbar-brand" href="/">

    //           <figure className="mb-0">
    //             <img src={l.logo} alt="logo-icon" width={290} />
    //           </figure>
    //         </a>
    //         <button className="navbar-toggler collapsed" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    //           <span className="navbar-toggler-icon" />
    //           <span className="navbar-toggler-icon" />
    //           <span className="navbar-toggler-icon" />
    //         </button>
    //         <div className="collapse navbar-collapse" id="navbarSupportedContent">
    //           <ul className="navbar-nav ml-auto">
    //             <li className="nav-item active">
    //               <a className="nav-link " href="/">HOME</a>
    //             </li>
    //             <li className="nav-item">
    //               <a className="nav-link p-0" href="#about-sec">ABOUT US</a>
    //             </li>
    //             <li className="nav-item">
    //               <a className="nav-link p-0" href="#services-sec">SERVICES</a>
    //             </li>
    //             <li className="nav-item">
    //               <a className="nav-link p-0" href="#pricing-sec">PRICING</a>
    //             </li>

    //             <li className="nav-item contact-btn">


    //               <div className="nav-item contact-btn d-flex align-items-center">
    // {user?.email ? (
    //   <li className="nav-item m-2">
    //     <Link to="/dashboard" className="nav-link p-0">Dashboard</Link>
    //   </li>
    // ) : (
    //   <li className="nav-item m-2">
    //     <Link to="/login" className="nav-link p-0">Log in</Link>
    //   </li>
    // )}

    // {users.map((u, index) => (
    //   u.userEmail === user?.email && u.userRole === 'Admin' && (
    //     <li className="nav-item m-2" key={index}>
    //       <Link to="/admin" className="nav-link p-0">Admin</Link>
    //     </li>
    //   )
    // ))}
    //               </div>

    //             </li>
    //           </ul>
    //         </div>
    //       </nav>
    //       {/* container */}
    //     </div>
    //     {/* header-con */}
    //   </header> )
    // }


    // </>

    <>

      <header>
        <div className="header-3_top d-none d-sm-block">
          <div className="container">

          </div>
        </div>
        <div id="header-sticky" className="header__area header-3">
          <div className="container">
            <div className="mega__menu-wrapper p-relative">
              <div className="header-3__main">
                <div className="header__logo">
                  <a href="/">
                  {
                    logo.map(l=> <div className="logo">
                      <img src={l.logo} alt="logo not found" />
                    </div>)
                  }
                    
                  </a>
                </div>
                <div className="mean__menu-wrapper d-none d-lg-block">
                  <div className="main-menu main-menu-3 onepagenav">
                    <nav id="mobile-menu">
                      <ul>
                        <li><a href="/">Homes</a></li>
                        <li><a href="#service">Services</a></li>
                        <li><a href="#about-sec">About Us</a></li>
                        <li><a href="#pricing">Pricing</a></li>
                        <li><a href="#testimonial">Testimonial</a></li>
                        <li><a href="#contact-sec">Contact Us</a></li>
                      </ul>
                    </nav>
                  </div>
                </div>
                <div className="header__right">
                  <div className="header__action d-flex align-items-center">
                    <div className="header__btn-wrap d-none d-sm-inline-flex ">


                      {user?.email ? (
                        <li className=" m-2">
                          <Link to="/dashboard" className=" rr-btn rr-btn__theme-4 text-white ">Dashboard </Link>
                        </li>
                      ) : (
                        <li className=" m-2">
                          <Link to="/login" className="rr-btn rr-btn__theme-4">Log in</Link>
                        </li>
                      )}

                      {users.map((u, index) => (
                        u.userEmail === user?.email && u.userRole === 'Admin' && (
                          <li className=" m-2" key={index}>
                            <Link to="/admin" className="rr-btn rr-btn__theme-4">Admin</Link>
                          </li>
                        )
                      ))}
                    </div>
                   
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default NavBar;
